import React, { Component } from "react";
import Layout from "../components/Layout";

class Blog extends Component {
    render() {
        return (
            <Layout>
                <div className="text-white text-2xl flex flex-col items-center">🔨 Under Construction :3</div>
            </Layout>
        )
    }
}

export default Blog;
